import httpapi from '../../utils/httpapi';
const collectApi = {
	//添加到收藏
	add(param,funsucc) {
		httpapi.post('/isv/collect/component/add', param).then(res => {
			console.log(res, 'add.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//查询收藏
	list(param,funsucc) {
		httpapi.post('/isv/collect/component/list', param).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//删除收藏
	del(param,funsucc) {
		httpapi.post('/isv/collect/component/del', param).then(res => {
			console.log(res, 'del.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	modifypwd(param,funsucc){
		httpapi.post('/isv/auth/user/modifypwd', param).then(res => {
			console.log(res, 'del.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	}
}
export default collectApi