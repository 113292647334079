<template>
  <el-container class="index-cons">
    <el-container class="main-con">
      <el-header class="index-header">
        <navcon></navcon>
      </el-header>
      <el-main clss="index-main" style="margin: 0 auto;min-width: 1360px;">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
// 导入组件
import navcon from '../../components/designer/navcon.vue'
export default {
  name: 'index',
  data() {
    return {
      showclass: 'asideshow',
      showtype: false
    }
  },
  // 注册组件
  components: {
    navcon
  },
  methods: {},
}
</script>
<style >
.index-cons {
  /* height: 100vh; */
  width: 100%;
  background-color: #F5F5F5;
  box-sizing: border-box;
}

.aside {
  width: 64px !important;
  height: 100vh;
  background-color: #FFFFFF;
  margin: 0px;
}
.asideshow {
  width: 240px !important;
  height: 100vh;
  background-color: #FFFFFF;
  margin: 0px;
}
.index-header,
.index-main {
  padding: 0px;
  /* border-left: 2px solid #333; */
}
</style>
